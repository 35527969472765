import styled from 'utils/emotion';
import { toPx } from 'utils/styles';

export const GridStyled = styled('div')(
  {
    display: 'flex',
    flex: '0 0 auto',
    flexWrap: 'wrap',
    // no width instead of 100% --> https://stackoverflow.com/questions/15733294/why-negative-right-margin-not-work
  },
  (props) => ({
    position: props.position,
    minHeight: props.minHeight,
    height: props.height,
    zIndex: props.zIndex,
    flexDirection: props.direction,
    flexBasis:
      (props.isNested && `calc(100% + ${toPx(props.theme.gridSpaceGutter)})`) ||
      props.flexBasis,
    marginLeft: `-${props.gutter / 2}px`,
    marginRight: `-${props.gutter / 2}px`,
    alignItems: props.alignitems,
    justifyContent: props.justifyContent,
    paddingTop: props.paddingTop,
    padding: props.padding,
    background: props.background,
    ':empty': {
      display: 'none',
    },
  }),
);
