import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Grid from 'components/common/Grid';
import GridItem from 'components/common/GridItem';
//import Image from 'components/common/ImageGatsbyCraft';
import Icon from 'components/common/Icon';
import Image from 'components/common/Image';

import {
  LinkStyled,
  FooterStyled,
  FooterSocial,
  FooterSocialList,
  FooterSocialItem,
  FooterSocialLink,
  FooterLogo,
  FooterLogoList,
  FooterLogoItem,
  FooterLogoLink,
  FooterLogoMedia,
  FooterTopStyled,
  FooterMenuStyled,
  FooterListStyled,
  FooterListWrapperStyled,
  FooterListItemStyled,
  FooterBottomStyled,
  FooterDecoWrapStyled,
  FooterDecoItemLeftStyled,
  FooterDecoBottleSpacerStyled,
  FooterDecoItemCenterStyled,
  FooterDecoItemRightStyled,
  FooterDecoItemBottleStyled,
  FooterCopyrightStyled,
  FooterGroupLogoStyled,
} from './FooterStyled';

function Footer({
  navMenuItems,
  socialListItems,
  brandCopyrightTitle,
  brandGroupLogo,
  footerLogoItems,
}) {
  const date = new Date();
  const year = date.getFullYear();

  //console.log(footerLogoItems['0']['media']['0'].url)

  return (
    <FooterStyled>
      <FooterTopStyled>
        <FooterDecoWrapStyled>
          <FooterDecoItemLeftStyled />
          <FooterDecoItemCenterStyled>
            <FooterDecoBottleSpacerStyled />
            <FooterDecoItemBottleStyled>
              <Icon type="warsteinerBottleOutline" />
            </FooterDecoItemBottleStyled>
          </FooterDecoItemCenterStyled>
          <FooterDecoItemRightStyled />
        </FooterDecoWrapStyled>

        {Boolean(socialListItems) && (
          <FooterSocial>
            <Grid>
              <GridItem>
                <FooterSocialList>
                  {socialListItems.map((socialItem, i) => (
                    <FooterSocialItem key={socialItem.id || i}>
                      <FooterSocialLink
                        target="_blank"
                        href={socialItem.socialLink}
                      >
                        <Icon type={socialItem.socialIcon} />
                      </FooterSocialLink>
                    </FooterSocialItem>
                  ))}
                </FooterSocialList>
              </GridItem>
            </Grid>
          </FooterSocial>
        )}

        {Boolean(navMenuItems) && (
          <FooterMenuStyled>
            <Grid>
              <GridItem>
                <FooterListStyled>
                  <FooterListWrapperStyled>
                    {navMenuItems.map((navItem, i) => (
                      <FooterListItemStyled key={navItem.id || i}>
                        <LinkStyled
                          to={navItem.link?.entry?.fullUri || navItem.link?.url}
                          target={navItem.link?.target}
                        >
                          {navItem.title || navItem.link[0].title}
                        </LinkStyled>
                      </FooterListItemStyled>
                    ))}
                  </FooterListWrapperStyled>
                </FooterListStyled>
              </GridItem>
            </Grid>
          </FooterMenuStyled>
        )}

        {Boolean(footerLogoItems) && (
          <FooterLogo>
            <Grid>
              <GridItem>
                <FooterLogoList>
                  {footerLogoItems.map((logoItem, i) => (
                    <FooterLogoItem key={logoItem.id || i}>
                      <FooterLogoLink
                        target="_blank"
                        href={logoItem.linkUrl}
                      >
                        <FooterLogoMedia>
                          <Image
                            src={logoItem.media['0'].url}
                            fluid={{src: logoItem.media['0'].url}}
                            imgStyle={{position: "relative"}}
                          />
                        </FooterLogoMedia>
                      </FooterLogoLink>
                    </FooterLogoItem>
                  ))}
                </FooterLogoList>
              </GridItem>
            </Grid>
          </FooterLogo>
        )}
      </FooterTopStyled>
      <FooterBottomStyled>
        <Grid between>
          <GridItem mobile={0}>
            <FooterCopyrightStyled>
              &copy; {year}{' '}
              {Boolean(brandCopyrightTitle) && brandCopyrightTitle}
            </FooterCopyrightStyled>
          </GridItem>
        </Grid>
      </FooterBottomStyled>
    </FooterStyled>
  );
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        craft {
          globals {
            globalFooter {
              globalFooterSubNav {
                ... on Craft_GlobalFooterSubNavFooterSubNavItem {
                  id
                  title: footerSubNavItemTitle
                  link: footerSubNavItemLink {
                    entry {
                      fullUri
                    }
                    url
                    customText
                    defaultText
                    target
                  }
                }
              },
              globalFooterLogos {
                ...on Craft_GlobalFooterLogosLogo {
                  id
                  linkUrl
                  media {
                    id
                    url
                  }
                }
              }
            }
            globalSocial {
              globalSocialList {
                socialTitle
                socialIcon
                socialLink
              }
            }
            globalBranding {
              globalBrandTitle
              globalBrandGroupLogo {
                url
                ...CraftImageFluid
              }
            }
          }
        }

        bottleFooter: file(
          relativePath: { eq: "warsteiner-bottle-footer.png" }
        ) {
          ...imageDefault
        }
      }
    `}
    render={data => (
      <Footer
        navMenuItems={data?.craft?.globals?.globalFooter?.globalFooterSubNav}
        socialListItems={data?.craft?.globals?.globalSocial?.globalSocialList}
        brandGroupLogo={
          data?.craft?.globals?.globalBranding?.globalBrandGroupLogo?.[0]
        }
        brandCopyrightTitle={
          data?.craft?.globals?.globalBranding?.globalBrandTitle
        }
        footerLogoItems={data?.craft?.globals?.globalFooter?.globalFooterLogos}
        {...props}
      />
    )}
  />
);
