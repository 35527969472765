import styled from 'utils/emotion';
import { toPx } from 'utils/styles';
import { colors } from 'components/common/Layout/variables';

export const TableWrapperStyled = styled('div')((props) => ({
  width: '100%',
  overflowX: 'auto',
  marginBottom: toPx(props.theme.space),
}));

export const TableStyled = styled('table')((props) => ({
  textAlign: 'left',
  borderCollapse: 'collapse',
  maxWidth: '100%',
  width: '100%',

  thead: {
    tr: {
      borderBottom: `1px solid ${colors.gold}`,
    },
  },

  th: {
    fontWeight: 'bold',
    padding: `${toPx(props.theme.spaceThird)} ${toPx(props.theme.space)}`,
  },

  'tbody tr:nth-of-type(odd)': {
    backgroundColor: 'rgba(255,255,255,.1)',
  },

  td: {
    padding: `${toPx(props.theme.spaceThird)} ${toPx(props.theme.space)}`,
  },
}));
