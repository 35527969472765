import React from 'react';

import Logo from 'components/common/Logo';
import Burger from 'components/common/Burger';

import { HeadroomStyled, HeaderStyled, HeaderItemStyled } from './HeaderStyled';

export default function Header({
  menuOpen,
  onLogoClick,
  onBurgerClick,
  darkSiteActive,
  menuMainNavActive
}) {
  return (
    <HeadroomStyled disableInlineStyles>
      <HeaderStyled>
        <HeaderItemStyled>
          <Logo to="/" onClick={onLogoClick} />
        </HeaderItemStyled>
        {!darkSiteActive && menuMainNavActive && (
          <HeaderItemStyled>
            <Burger active={menuOpen} onClick={onBurgerClick} />
          </HeaderItemStyled>
        )}
      </HeaderStyled>
    </HeadroomStyled>
  );
}
