import styled from 'utils/emotion';

export const VideoWrapperStyled = styled('div')({
  width: '100%',
});

export const VideoWrapperInnerStyled = styled('div')({
  position: 'relative',
  display: 'block',
  width: '100%',
  padding: '0',
  overflow: 'hidden',

  '&::before': {
    display: 'block',
    content: '""',
    paddingTop: '56.25%',
  },

  iframe: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    width: '100% !important',
    height: '100% !important',
    border: '0',
  },
});
