import styled from 'utils/emotion';
import media from 'utils/mediaqueries';
import { toPx } from 'utils/styles';

import Link from 'components/common/Link';

import { wrapSizes } from 'components/common/Layout/variables';

export const FooterStyled = styled('footer')(
  {
    position: 'relative',
    width: '100%',
    paddingBottom: '40px',
    paddingTop: '80px',
    zIndex: '3',
    [media('tablet')]: {
      paddingBottom: '40px',
    },
  },

  (props) => ({
    backgroundColor: props.theme.colorSecondary,
  }),
);

export const FooterTopStyled = styled('div')({
  position: 'relative',
  [media('tablet')]: {
    paddingBottom: '40px',
  },
});

export const FooterSocial = styled('div')(
  {
    position: 'relative',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  (props) => ({
    ':before': {
      content: '""',
      display: 'block',
      backgroundColor: props.theme.borderColorTertiary,
      position: 'absolute',
      width: '100%',
      height: 1,
      bottom: 0,
      left: 0,
      zIndex: '2',
      [media('tablet')]: {
        display: 'none',
      },
    },
    ':after': {
      content: '""',
      display: 'block',
      backgroundColor: props.theme.borderColorTertiary,
      position: 'absolute',
      width: '100%',
      height: 1,
      top: 0,
      left: 0,
      zIndex: '2',
      [media('tablet')]: {
        display: 'none',
      },
    },
  }),
);

export const FooterSocialList = styled('ul')({
  display: 'flex',
  listStyle: 'none',
  listStyleType: 'none',
  width: '100%',
  justifyContent: 'space-between',
  [media('tablet')]: {
    justifyContent: 'flex-start',
    marginLeft: '-12px',
  },
});

export const FooterSocialItem = styled('li')({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
});

export const FooterSocialLink = styled('a')(
  {
    display: 'flex',
    [media('tablet')]: {
      padding: '12px',
    },
    svg: {
      maxHeight: '26px',
    },
    '&:hover': {
      opacity: '0.8',
    },
  },
  (props) => ({
    transition: `opacity ${props.theme.transitionLink}`,
    'svg path': {
      fill: 'white',
      // fill: props.theme.colorTertiary,
    },
  }),
);

export const FooterLogo = styled('div')(
  {
    position: 'relative',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  (props) => ({
    ':before': {
      content: '""',
      display: 'block',
      backgroundColor: props.theme.borderColorTertiary,
      position: 'absolute',
      width: '100%',
      height: 1,
      bottom: 0,
      left: 0,
      zIndex: '2',
      [media('tablet')]: {
        display: 'none',
      },
    },
    ':after': {
      content: '""',
      display: 'block',
      backgroundColor: props.theme.borderColorTertiary,
      position: 'absolute',
      width: '100%',
      height: 1,
      top: 0,
      left: 0,
      zIndex: '2',
      [media('tablet')]: {
        display: 'none',
      },
    },
  }),
);

export const FooterLogoList = styled('ul')({
  display: 'flex',
  listStyle: 'none',
  listStyleType: 'none',
  width: '100%',
  justifyContent: 'flex-start',
  [media('tablet')]: {
    justifyContent: 'flex-start',
  },
});

export const FooterLogoItem = styled('li')({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
});

export const FooterLogoLink = styled('a')(
  {
    display: 'flex',
    padding: '0 20px 0 0',
    [media('tablet')]: {
      
    },
    svg: {
      maxHeight: '26px',
    },
    '&:hover': {
      opacity: '0.8',
    },
  },
  (props) => ({
    transition: `opacity ${props.theme.transitionLink}`,
    'svg path': {
      fill: 'white',
      // fill: props.theme.colorTertiary,
    },
  }),
);

export const FooterLogoMedia = styled('div')({
  display: 'flex',
  maxWidth: 75,
  [media('tablet')]: {
    maxWidth: 100,
  },
});

export const FooterMenuStyled = styled('div')({
  paddingTop: '40px',
  paddingBottom: '40px',
  [media('tablet')]: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
});

export const FooterListStyled = styled('nav')({
  maxWidth: '768px',
  [media('tablet')]: {
    paddingRight: '100px',
  },
});

export const FooterListWrapperStyled = styled('ul')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
});

export const FooterListItemStyled = styled('li')({
  listStyle: 'none',
  listStyleType: 'none',
  padding: '12px 12px 12px 0',
  width: '100%',
  [media('tablet')]: {
    width: 'auto',
  },
});

export const FooterDecoBottleSpacerStyled = styled('div')(
  {
    position: 'relative',
    display: 'table-cell',
  },
  (props) => ({
    ':before': {
      content: '""',
      display: 'block',
      backgroundColor: props.theme.borderColorTertiary,
      position: 'absolute',
      width: '100%',
      height: 1,
      bottom: 5,
      left: 0,
      zIndex: '2',
    },
  }),
);

export const FooterDecoWrapStyled = styled('div')({
  position: 'absolute',
  bottom: '0',
  width: '100%',
  display: 'table',
  pointerEvents: 'none',
});

export const FooterDecoItemCenterStyled = styled('div')({
  width: '100%',
  display: 'table',

  [media('tabletFluid')]: {
    width: `calc(${toPx(wrapSizes.tabletFluid)} - 40px)`,
  },
  [media('laptop')]: {
    width: `calc(${toPx(wrapSizes.laptop)} - 40px)`,
  },
  [media('desktop')]: {
    width: `calc(${toPx(wrapSizes.desktop)} - 40px)`,
  },
});

export const FooterDecoItemLeftStyled = styled('div')(
  {
    position: 'relative',
    display: 'table-cell',
    width: '24px',
    [media('tabletFluid')]: {
      width: '40%',
    },
  },
  (props) => ({
    ':before': {
      content: '""',
      display: 'block',
      backgroundColor: props.theme.borderColorTertiary,
      position: 'absolute',
      width: '100%',
      height: 1,
      bottom: 5,
      left: 0,
      zIndex: '2',
    },
  }),
);

export const FooterDecoItemRightStyled = styled('div')(
  {
    position: 'relative',
    display: 'table-cell',
    width: '24px',
    [media('tabletFluid')]: {
      width: '40%',
    },
    [media('desktop')]: {
      width: '36%',
    },
    [media('desktopFluid')]: {
      width: '17%',
    },
  },
  (props) => ({
    ':before': {
      content: '""',
      display: 'block',
      backgroundColor: props.theme.borderColorTertiary,
      position: 'absolute',
      width: '100%',
      height: 1,
      bottom: 5,
      left: 0,
      zIndex: '2',
    },
  }),
);

export const FooterDecoItemBottleStyled = styled('div')({
  position: 'relative',
  top: '3px',
  display: 'table-cell',
  width: '43px',
  height: '148px',
  svg: {
    width: '43px',
    height: '140px',
  },
});

export const FooterBottomStyled = styled('div')(
  {
    position: 'relative',
    paddingTop: '40px',
  },
  (props) => ({
    ':before': {
      content: '""',
      display: 'block',
      backgroundColor: props.theme.borderColorTertiary,
      position: 'absolute',
      width: '100%',
      height: 0,
      top: 0,
      left: 0,
      zIndex: '2',
      [media('tablet')]: {
        height: '0px',
      },
    },
  }),
);

export const FooterCopyrightStyled = styled('span')(
  {
    display: 'block',
    height: '29px',
    fontSize: '12px',
    lineHeight: '29px',
    [media('tablet')]: {
      fontSize: '14px',
      lineHeight: '40px',
      height: '40px',
    },
  },
  (props) => ({
    fontFamily: props.theme.fontPrimary,
    color: props.theme.colorTertiary,
    transition: `opacity ${props.theme.transitionLink}`,
  }),
);

export const FooterGroupLogoStyled = styled('div')({
  width: '100px',
  display: 'block',
  [media('tablet')]: {
    width: '140px',
  },
});

export const LinkStyled = styled(Link)(
  {
    fontSize: '16px',
    fontWeight: 200,
    padding: '12px 12px 12px 0',
    '&:hover': {
      opacity: '0.8',
    },
  },
  (props) => ({
    fontFamily: props.theme.fontPrimary,
    color: props.theme.footerMenuLinkColor,
    transition: `opacity ${props.theme.transitionLink}`,
  }),
);
