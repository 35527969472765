import styled from 'utils/emotion';
import media from 'utils/mediaqueries';
import { toPercent } from 'utils/math';

import { breakpoints } from 'components/common/Layout/variables';

export const GridItemStyled = styled('div')(
  {
    flex: '0 0 auto',
    msFlex: '0 0 auto',
  },
  (props) => ({
    display: props.display,
    alignSelf: props.alignSelf,
    background: props.background,
    height: props.height,
    // Autoload wrapSizes
    ...Object.assign(
      {},
      ...Object.keys(breakpoints).map((key, i) =>
        // Avoid first query by default since its mobile first ~DH
        i < 1
          ? {
              maxWidth: (props[key] === 0 && '100%') || toPercent(props[key]),
              flexBasis: (props[key] === 0 && 'auto') || toPercent(props[key]),
            }
          : {
              [media(`${key}`)]: {
                maxWidth: (props[key] === 0 && '100%') || toPercent(props[key]),
                flexBasis:
                  (props[key] === 0 && 'auto') || toPercent(props[key]),
              },
            },
      ),
    ),
    padding: `0 ${props.gutter / 2}px`,
    order: props.order,
    textAlign: props.textAlign,
    zIndex: props.zIndex,
  }),
);
