import React from 'react';
import styled from 'utils/emotion';

const StrongStyled = styled('strong')({
  fontWeight: 700,
});

export default function Strong({ children, rest }) {
  return <StrongStyled {...rest}>{children}</StrongStyled>;
}
