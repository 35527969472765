import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import { HeadlineGoldStyled } from './HeadlineGoldStyled';

function HeadlineGold({ goldTexture, omitTexture, children, ...rest }) {
  return (
    <HeadlineGoldStyled
      goldTexture={omitTexture ? null : goldTexture}
      {...rest}
    >
      {children}
    </HeadlineGoldStyled>
  );
}

HeadlineGold.propTypes = {
  goldTexture: PropTypes.string.isRequired,
  omitTexture: PropTypes.bool,
};

HeadlineGold.defaultProps = {
  omitTexture: null,
};

export default props => (
  <StaticQuery
    query={graphql`
      query {
        goldTexture: file(relativePath: { eq: "effects/gold.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1024, toFormat: PNG) {
              src
            }
          }
        }
      }
    `}
    render={data => (
      <HeadlineGold
        goldTexture={data?.goldTexture?.childImageSharp?.fluid.src}
        {...props}
      />
    )}
  />
);
