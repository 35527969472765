import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

/**
 * Function:
 * Adjust backend url.
 *
 * @param string url
 */
function adjustBackendUrl(url) {
  // Skip adjustment if cms url isn't included.
  if (
    !url ||
    typeof url !== 'string' ||
    url.indexOf(process.env.GATSBY_CMS_URL) === -1
  ) {
    return url;
  }

  // Check if the link points to a file.
  const filename = url.split('/').pop();
  const isFile = /.+\..+/.test(filename);

  if (isFile) {
    return url;
  }

  // If it's a normal cms url, remove the cms link, so it's seen as internal link.
  return url.replace(process.env.GATSBY_CMS_URL, '');
}

export default function Link({
  children,
  target,
  className,
  activeClassName,
  rel,
  to,
  onClick,
}) {
  const hasClickEvent = typeof onClick === 'function';

  // Warn if nothing would happen and ignore the link.
  if (!to && !hasClickEvent) {
    console.warn('Invalid target for a link on this page. Children:', children);
    return <span className={className}>{children}</span>;
  }

  // Adjust urls from backend.
  const toStripped = adjustBackendUrl(to);

  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(toStripped);

  if (internal) {
    return target === '_blank' ? (
      // Use <a> for internal links that open in a new tab.
      <a
        href={toStripped}
        target={target}
        className={className}
        rel={rel}
        onClick={onClick}
      >
        {children}
      </a>
    ) : (
      // Use Gatsby <Link> for internal links.
      <GatsbyLink
        to={toStripped}
        className={className}
        activeClassName={activeClassName}
        rel={rel}
        onClick={onClick}
      >
        {children}
      </GatsbyLink>
    );
  }

  // Use <OutboundLink> for external links.
  return (
    <a
      href={toStripped}
      target={target}
      rel="noopener noreferrer"
      className={className}
      onClick={onClick}
    >
      {children}
    </a>
  );
}

Link.propTypes = {
  target: PropTypes.string,
};

Link.defaultProps = {
  target: null,
};
