import media from 'utils/mediaqueries';
import styled from 'utils/emotion';
import { toPx } from 'utils/styles';

export const StyledH1 = styled('h1')(
  {
    // H3
    fontSize: '45px',
    lineHeight: '48px',
    fontWeight: 400,
    textTransform: 'uppercase',

    [media(738, 'desktop')]: {
      // H2
      fontSize: '64px',
      lineHeight: '64px',
    },
    [media('desktop')]: {
      // H1
      fontSize: '95px',
      lineHeight: '85px',
    },
  },
  (props) => ({
    color: props.theme.colorTertiary,
    fontFamily: props.theme.fontSecondary,
  }),
);

export const StyledH2 = styled('h2')(
  {
    // H4
    fontSize: '34px',
    lineHeight: '38px',
    fontWeight: 400,
    textTransform: 'uppercase',

    [media(738, 'desktop')]: {
      // H3
      fontSize: '45px',
      lineHeight: '48px',
    },
    [media('desktop')]: {
      // H2
      fontSize: '64px',
      lineHeight: '64px',
    },
  },
  (props) => ({
    color: props.theme.colorTertiary,
    fontFamily: props.theme.fontSecondary,
    margin: `${props.theme.spaceHalf}px 0`,
  }),
);

export const StyledH3 = styled('h3')(
  {
    // H5
    fontSize: '22px',
    lineHeight: '24px',
    fontWeight: 400,
    textTransform: 'uppercase',

    [media(738, 'desktop')]: {
      // H4
      fontSize: '34px',
      lineHeight: '38px',
    },

    [media('desktop')]: {
      // H3
      fontSize: '45px',
      lineHeight: '48px',
    },
  },
  (props) => ({
    color: props.theme.colorTertiary,
    fontFamily: props.theme.fontSecondary,
    margin: `${props.theme.spaceHalf}px 0`,
  }),
);

export const StyledH4 = styled('h4')(
  {
    // H5
    fontSize: '22px',
    lineHeight: '24px',
    fontWeight: 400,
    textTransform: 'uppercase',
    [media('desktop')]: {
      // H4
      fontSize: '34px',
      lineHeight: '38px',
    },
  },
  (props) => ({
    color: props.theme.colorTertiary,
    fontFamily: props.theme.fontSecondary,
    margin: `${props.theme.spaceHalf}px 0`,
  }),
);

export const StyledH5 = styled('h5')(
  {
    fontSize: '22px',
    lineHeight: '24px',
    fontWeight: 400,
  },
  (props) => ({
    color: props.theme.colorTertiary,
    fontFamily: props.theme.fontSecondary,
    margin: `${props.theme.spaceHalf}px 0`,
  }),
);

export const StyledH6 = styled('h6')(
  {
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: 400,
  },
  (props) => ({
    color: props.theme.colorTertiary,
    fontFamily: props.theme.fontPrimary,
    margin: `${props.theme.spaceHalf}px 0`,

    [media('md')]: {
      margin: `${props.theme.spaceHalf}px 0 ${props.theme.space}`,
    },
  }),
);
