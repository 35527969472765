import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import {
  StyledH1,
  StyledH2,
  StyledH3,
  StyledH4,
  StyledH5,
  StyledH6,
} from './HeadlineStyled';

function Headline({ element, children, className, innerRef }) {
  const ComponentList = {
    h1: StyledH1,
    h2: StyledH2,
    h3: StyledH3,
    h4: StyledH4,
    h5: StyledH5,
    h6: StyledH6,
  };
  const Component = ComponentList[element] || StyledH3;

  return (
    <Component className={className} ref={innerRef}>
      {children}
    </Component>
  );
}

Headline.propTypes = {
  element: PropTypes.string,
};

Headline.defaultProps = {
  element: 'h3',
};

export default forwardRef((props, ref) => <Headline {...props} ref={ref} />);
