import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import themeDefault from 'components/common/Layout/themes/default';
import { GridContext } from 'components/common/Grid';
import GridWrap from 'components/common/GridWrap';

import { GridStyled } from './GridStyled';

export default function Grid({
  column,
  row,
  children,
  gutter,
  width,
  height,
  zIndex,
  minHeight,
  left,
  center,
  right,
  top,
  middle,
  bottom,
  between,
  around,
  reverse,
  padding,
  paddingTop,
  position,
  isNestedForce,
  background,
  margin,
  className,
  innerRef,
  style,
}) {
  const { isNested } = useContext(GridContext);

  const direction = {
    column: 'column',
    row: 'row',
    reverse: `${(column && 'column') || (row && 'row')}-reverse`,
  };

  const alignitems = {
    top: 'flex-start',
    middle: 'center',
    bottom: 'flex-end',
  };

  const justifyContent = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
    between: 'space-between',
    around: 'space-around',
  };

  return (
    // Grids within a grid are nested (`isNested = true`) - see `GridContext.js` for first `Grid`
    <GridContext.Provider value={{ gutter, isNested: true }}>
      <GridWrap
        isNested={isNestedForce || isNested}
        width={width}
        height={height}
        gutter={gutter}
        position={position}
        zIndex={zIndex}
        background={background}
        margin={margin}
        style={style}
      >
        <GridStyled
          isNested={isNestedForce || isNested}
          background={background}
          gutter={gutter}
          height={height}
          minHeight={minHeight}
          paddingTop={paddingTop}
          padding={padding}
          position={position}
          zIndex={zIndex}
          alignitems={
            alignitems[
              (top && 'top') || (middle && 'middle') || (bottom && 'bottom')
            ]
          }
          justifyContent={
            justifyContent[
              (left && 'left') ||
                (center && 'center') ||
                (right && 'right') ||
                (around && 'around') ||
                (between && 'between')
            ]
          }
          direction={
            direction[
              (reverse && 'reverse') || (column && 'column') || (row && 'row')
            ]
          }
          className={className}
          ref={innerRef}
        >
          {children}
        </GridStyled>
      </GridWrap>
    </GridContext.Provider>
  );
}

Grid.propTypes = {
  gutter: PropTypes.number,
  left: PropTypes.bool,
  center: PropTypes.bool,
  right: PropTypes.bool,
  top: PropTypes.bool,
  middle: PropTypes.bool,
  bottom: PropTypes.bool,
  between: PropTypes.bool,
  around: PropTypes.bool,
  reverse: PropTypes.bool,
  position: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isNestedForce: PropTypes.bool,
  row: PropTypes.bool,
};

Grid.defaultProps = {
  gutter: themeDefault.gridSpaceGutter,
  left: false,
  center: false,
  right: false,
  top: false,
  middle: false,
  bottom: false,
  between: false,
  around: false,
  reverse: false,
  position: 'relative',
  height: '100%',
  isNestedForce: false,
  row: true,
};

// TODO: ~DH
// 1. Unified Fractions (which means you can set a griditem width over the parent, maybe use context ?)
