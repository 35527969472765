import React from 'react';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';

const Favicons = () => (
  <Helmet>
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href={withPrefix('/favicons/apple-touch-icon.png')}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href={withPrefix('/favicons/favicon-32x32.png')}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href={withPrefix('/favicons/favicon-16x16.png')}
    />
    <link rel="manifest" href={withPrefix('/favicons/site.webmanifest')} />
    <link
      rel="mask-icon"
      href={withPrefix('/favicons/safari-pinned-tab.svg')}
      color="#3e3e3e"
    />
    <link rel="shortcut icon" href={withPrefix('/favicons/favicon.ico')} />
    <meta
      name="msapplication-config"
      content={withPrefix('/favicons/browserconfig.xml')}
    />
    <meta name="msapplication-TileColor" content="#000000" />
    <meta name="theme-color" content="#000000" />
  </Helmet>
);

export default Favicons;
