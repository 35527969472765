import media from 'utils/mediaqueries';
import styled from 'utils/emotion';
import { toPx } from 'utils/styles';

export const TextPStyled = styled('p')(
  {
    fontWeight: 'inherit',
    fontStyle: 'inherit',
    textTransform: 'inherit',
    fontSize: 'inherit',
  },
  (props) => ({
    margin: `${toPx(props.theme.spaceHalf)} 0`,
    color: props.theme.colorPrimary,
    '.strong': {
      fontWeight: 'normal',
      color: props.theme.colorTertiary,
    },
  }),
);

export const TextSpanStyled = styled('span')({}, (props) => ({
  '.strong': {
    fontWeight: 'normal',
    color: props.theme.colorTertiary,
  },
}));

export const TextDivStyled = styled('div')({}, (props) => ({
  '.strong': {
    fontWeight: 'normal',
    color: props.theme.colorTertiary,
  },

  fontSize: props.theme.baseTextBodyFontSize,
  lineHeight: props.theme.baseTextBodyLineHeight,

  [media('desktop')]: {
    fontSize: props.theme.baseTextBodyFontSizeDesktop,
  },
}));
