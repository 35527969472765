import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import PageTypeContext from 'utils/context/PageTypeContext';

import {
  BurgerStyled,
  BurgerInnerStyled,
  BurgerBarStyled,
} from './BurgerStyled';

export default function Burger({ active, onClick }) {
  const pageType = useContext(PageTypeContext);

  return (
    <BurgerStyled onClick={onClick} active={active} pageType={pageType}>
      <BurgerInnerStyled>
        <BurgerBarStyled active={active} />
        <BurgerBarStyled active={active} />
        <BurgerBarStyled active={active} />
      </BurgerInnerStyled>
    </BurgerStyled>
  );
}

Burger.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool,
};

Burger.defaultProps = {
  onClick: () => console.log('Your 🍔 seems to be missing a click handler.'),
  active: false,
};
