import React from 'react';
import PropTypes from 'prop-types';
import SwitchTheme from 'components/common/SwitchTheme';

export default function Blockquote({ theme, children }) {
  return (
    <SwitchTheme name={theme}>
      <>{children}</>
    </SwitchTheme>
  );
}

Blockquote.propTypes = {
  theme: PropTypes.string,
};

Blockquote.defaultProps = {
  theme: 'default',
};
