import { useLayoutEffect } from 'react';

export function useLockBodyScroll(isActive) {
  useLayoutEffect(() => {
    // Prevent scrolling on mount
    document.body.style.overflow = isActive ? 'hidden' : 'visible';

    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [isActive]); // Empty array ensures effect is only run on mount and unmount, or if is active
}
