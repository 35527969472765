import React from 'react';
import PropTypes from 'prop-types';

import { withTheme } from 'utils/emotion';

import SwitchTheme from 'components/common/SwitchTheme';

import {
  StyledButton,
  StyledButtonText,
  StyledButtonIconLeft,
  StyledButtonIconRight,
} from './ButtonStyled';

/**
 * @param {*} param0
 */
function Button({
  to,
  themeName,
  className,
  children,
  icon,
  iconLeft,
  target,
  disabled,
  onClick,
}) {
  return (
    <SwitchTheme name={themeName}>
      <StyledButton
        to={to}
        target={target}
        themeName={themeName}
        className={className}
        icon={icon}
        iconLeft={iconLeft}
        disabled={disabled}
        onClick={onClick}
      >
        {icon && iconLeft && <StyledButtonIconLeft type={icon} />}
        <StyledButtonText>{children}</StyledButtonText>
        {icon && !iconLeft && <StyledButtonIconRight type={icon} />}
      </StyledButton>
    </SwitchTheme>
  );
}

Button.propTypes = {
  to: PropTypes.string,
  icon: PropTypes.string,
  target: PropTypes.string,
  themeName: PropTypes.string,
  disabled: PropTypes.bool,
  iconLeft: PropTypes.bool,
};

Button.defaultProps = {
  to: undefined,
  icon: null,
  target: '_top',
  themeName: null, // Do not change this ~DH
  disabled: false,
  iconLeft: false,
};

export default withTheme(Button);
