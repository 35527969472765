import { injectGlobal } from 'emotion';
import themeDefault from 'components/common/Layout/themes/default';

/* eslint no-unused-expressions: 0 */
injectGlobal`
  @import url("https://use.typekit.net/aco2wcg.css");

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    font-family: ${themeDefault.fontPrimary};
    color: ${themeDefault.colorPrimary};
  }

  /* hide preloader classes */
  .no-js .preloaderOverlayWrap,
  .wf-active .preloadOverlayWrap,
  .forceShowPage .preloadOverlayWrap {
    z-index: 1;
  }

  .no-js .preloadOverlay,
  .preloadOverlay {
    opacity: 1;
  }

  .no-js .preloadOverlay,
  .wf-active .preloadOverlay,
  .forceShowPage .preloadOverlay {
    opacity: 0;
  }

  body {
    background: ${themeDefault.bodyBackground};
    font-size: 18px;
    line-height: 1.4;
    overflow-y: scroll;
    -webkit-font-smoothing: antialiased;
  }

  .no-scroll {
    overflow: hidden !important;
    position: fixed;
    width: 100%;
  }

  ul {
    list-style-position: inside;
  }

  *::-webkit-scrollbar {
    width: ${themeDefault.scrollBarWidth};
  }

  *::-webkit-scrollbar-track {
    background: ${themeDefault.scrollBarTrack};
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${themeDefault.scrollBarThumb};
  }

  h1, h2, h3, h4, h5, h6 {
    a {
      color: inherit!important;
    }
  }

  a {
    color: inherit;
    font-size: inherit;
    text-decoration: none;
  }

  ul {
    list-style-position: inside;
    margin: ${themeDefault.spaceHalf}px 0;
  }

  p {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  ::-moz-selection { background: ${themeDefault.colorTertiary}; color: white; }
  ::selection { background: ${themeDefault.colorTertiary}; color: white; }

  /* removal of select styles and arrow ~DH */
  select::-ms-expand {
    /* IE 11 */
    display: none;
  }
  select {
    /*For FireFox*/
    -webkit-appearance: none;
    /*For Chrome*/
    -moz-appearance: none;
  }
`;
