import React from 'react';
import PropTypes from 'prop-types';

import { TableWrapperStyled, TableStyled } from './TableStyled';

export default function Table({ children }) {
  return (
    <TableWrapperStyled>
      <TableStyled>{children}</TableStyled>
    </TableWrapperStyled>
  );
}

Table.propTypes = {};

Table.defaultProps = {};
