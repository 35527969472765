import styled from '@emotion/styled'; // Sorry, need working inheritance which utils/emotion doesn't provide. ~RS
import { toPx } from 'utils/styles';
import media from 'utils/mediaqueries';

import Image from 'components/common/ImageGatsbyCraft';
import Link from 'components/common/Link';

export const TileStyled = styled('div')(
  {
    position: 'relative',
    backgroundColor: 'black',
    height: '100%',
    width: '100%',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    backfaceVisibility: 'hidden',
    transition:
      'opacity .25s .3s, transform .25s .3s cubic-bezier(.22,.83,.51,.9)',
    transform: 'translate(-100%, 0)',
    opacity: 0,
  },
  (props) =>
    props.active && {
      transform: 'translate(0, 0)',
      opacity: 1,
    },
);

export const BgStyled = styled(Image)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  transition: 'transform .4s cubic-bezier(0.63, -0.01, 0.18, 1.15)',
  backfaceVisibility: 'hidden',

  [`${TileStyled}:hover &`]: {
    transform: 'scale(1.05)',
  },
});

export const TitleStyled = styled('div')(
  {
    position: 'relative',
    fontSize: 28,
    lineHeight: 0.9,
    textTransform: 'uppercase',
    opacity: 0,
    textShadow: '1px 3px 6px rgba(0,0,0,.2)',

    [media('tabletFluid')]: {
      fontSize: 36,
    },
  },
  (props) => ({
    color: props.theme.colors.goldLight,
    fontFamily: props.theme.fontSecondary,
  }),
  (props) =>
    props.active && {
      opacity: 1,
    },
);

export const TitleInnerStyled = styled('span')(
  {
    position: 'relative',
    display: 'inline-block',
  },
  (props) => ({
    margin: `${toPx(props.theme.space)}`,
  }),
);

export const LinkStyled = styled(Link)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  WebkitTapHighlightColor: 'transparent',
});
