import React, { useContext } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { parseAsContent } from 'utils/parser';
import { useLockBodyScroll } from 'utils/hooks/useLockBodyScroll';
import isLegalPage from 'utils/isLegalPage';

import Link from 'components/common/Link';
import { AgegateContext } from 'components/container/Agegate';

import {
  AgegateStyled,
  InnerStyled,
  IconStyled,
  ContentStyled,
  HeadlineStyled,
  TextStyled,
  ButtonWrapStyled,
  ButtonAcceptStyled,
  ButtonDeclineStyled,
  FooterStyled,
} from './AgegateStyled';

function Agegate({ data }) {
  const {
    globalAgeGateHeadline,
    globalAgeGateText,
    globalAgeGateYes,
    globalAgeGateNo,
    globalAgeGateFooterLinks,
  } = data;

  const { confirmedAge, handleAcceptClick } = useContext(AgegateContext);

  const showAgegate = !confirmedAge && !isLegalPage()

  useLockBodyScroll(showAgegate);

  // Only show restrictions prompt when cookies got loaded and the values are "false". ~RS
  return showAgegate ? (
    <AgegateStyled>
      <InnerStyled>
        <IconStyled type="logoWarsteiner" />
        <ContentStyled>
          <HeadlineStyled>{globalAgeGateHeadline}</HeadlineStyled>
          <TextStyled element="div">
            {parseAsContent(globalAgeGateText?.content)}
          </TextStyled>
        </ContentStyled>
        <ButtonWrapStyled>
          <ButtonAcceptStyled onClick={() => handleAcceptClick()}>
            {globalAgeGateYes}
          </ButtonAcceptStyled>
          <ButtonDeclineStyled
            to="https://www.bierbewusstgeniessen.de/bier-sorry-erst-ab-16/"
            rel="nofollow noopen"
            target="_blank"
          >
            {globalAgeGateNo}
          </ButtonDeclineStyled>
        </ButtonWrapStyled>
      </InnerStyled>
      {globalAgeGateFooterLinks && (
        <FooterStyled>
          {globalAgeGateFooterLinks.map(({ id, fullUri, title }) => (
            <Link key={id} to={fullUri}>
              {title}
            </Link>
          ))}
        </FooterStyled>
      )}
    </AgegateStyled>
  ) : null;
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query AgegateQuery {
        craft {
          entry(siteId: 1, type: AgegateDe) {
            ... on Craft_AgegateDe {
              title
              globalAgeGateYes
              globalAgeGateNo
              globalAgeGateHeadline
              globalAgeGateText {
                content
              }
              globalAgeGateFooterLinks {
                id
                fullUri
                title
              }
            }
          }
        }
      }
    `}
    render={(data) => <Agegate data={data?.craft?.entry} {...props} />}
  />
);
