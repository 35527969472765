// const vars = require('components/common/Layout/variables');
// const utils = require('utils/styles');

module.exports = {
  name: 'transparent',
  inherit: null,

  // colorPrimary: INHERIT,
  // colorPrimaryHover: INHERIT
  // colorSecondary: INHERIT
  // colorSecondaryHover: INHERIT
  // colorTertiary: INHERIT
  // colorTertiaryHover: INHERIT,

  backgroundPrimary: 'transparent',
  backgroundPrimaryHover: 'transparent',
  // backgroundSecondary: INHERIT,
  // backgroundSecondaryHover: INHERIT,
  // backgroundTertiary: INHERIT,
  // backgroundTertiaryHover: INHERIT,

  borderColorPrimary: 'transparent',
  borderColorPrimaryHover: 'transparent',
  // borderColorSecondary: INHERIT,
  // borderColorSecondaryHover: INHERIT,
  // borderColorTertiary: INHERIT,
  // borderColorTertiaryHover: INHERIT,

  // fontPrimary: INHERIT,
  // fontSecondary: INHERIT,

  // ...vars.sizes, INHERIT

  // component variables
  // buttonDefaultColor: 'color',
  // buttonDefaultColorHover: INHERIT,
  // buttonDefaultBackground: INHERIT,
  // buttonDefaultBackgroundHover: INHERIT,
  // buttonDefaultBorderColor: INHERIT,
  // buttonDefaultBorderColorHover: INHERIT,

  // buttonAlternativeColor: 'color',
  // buttonAlternativeColorHover: INHERIT,
  // buttonAlternativeBackground: INHERIT,
  // buttonAlternativeBackgroundHover: INHERIT,
  // buttonAlternativeBorderColor: INHERIT,
  // buttonAlternativeBorderColorHover: INHERIT,

  // buttonColorColor: 'color',
  // buttonColorColorHover: INHERIT,
  // buttonColorBackground: INHERIT,
  // buttonColorBackgroundHover: INHERIT,
  // buttonColorBorderColor: INHERIT,
  // buttonColorBorderColorHover: INHERIT,
};
