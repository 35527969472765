import React from 'react';

import media from 'utils/mediaqueries';
import styled from 'utils/emotion';

import Link from 'components/common/Link';
import Icon from 'components/common/Icon';

const StyledLogo = styled('div')({
  width: '68px',
  height: '68px',
  margin: '0 auto',
  pointerEvents: 'all',

  [media('tablet')]: {
    width: '96px',
    height: '96px',
  },
});

const IconStyled = styled(Icon)({
  width: '100%',
  height: '100%',
});

export default function Logo({ to, onClick, className }) {
  return (
    <StyledLogo className={className}>
      <Link to={to} onClick={onClick}>
        <IconStyled type="logoWarsteiner" />
      </Link>
    </StyledLogo>
  );
}
