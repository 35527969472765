const vars = require('components/common/Layout/variables');
// const utils = require('utils/styles');

module.exports = {
  name: 'alternative',
  inherit: null,

  colorPrimary: vars.colors.black,
  colorPrimaryHover: vars.colors.black,
  colorSecondary: vars.colors.white,
  colorSecondaryHover: vars.colors.white,
  // colorTertiary: INHERIT
  // colorTertiaryHover: INHERIT,

  backgroundPrimary: vars.colors.white,
  backgroundPrimaryHover: vars.colors.white,
  backgroundSecondary: vars.colors.black,
  backgroundSecondaryHover: vars.colors.black,
  // backgroundTertiary: INHERIT
  // backgroundTertiaryHover: INHERIT,

  borderColorPrimary: vars.colors.black,
  borderColorPrimaryHover: vars.colors.black,
  borderColorSecondary: vars.colors.white,
  borderColorSecondaryHover: vars.colors.white,
  // borderColorTertiary: INHERIT,
  // borderColorTertiaryHover: INHERIT,
  // borderRadiusPrimary: INHERIT,

  // fontPrimary: INHERIT,
  // fontSecondary: INHERIT,

  // ...vars.sizes, INHERIT

  // component variables
  buttonDefaultColor: vars.colors.white,
  buttonDefaultColorHover: vars.colors.white,
  buttonDefaultBackground: 'transparent',
  buttonDefaultBackgroundHover: vars.colors.black,
  buttonDefaultBorderColor: vars.colors.black,
  buttonDefaultBorderColorHover: vars.colors.black,

  buttonAlternativeColor: vars.colors.black,
  buttonAlternativeColorHover: vars.colors.white,
  buttonAlternativeBackground: 'transparent',
  buttonAlternativeBackgroundHover: vars.colors.black,
  buttonAlternativeBorderColor: vars.colors.black,
  buttonAlternativeBorderColorHover: vars.colors.black,

  linkColor: vars.colors.black,
  linkColorHover: vars.colors.black,

  overlayBackground: vars.colors.white,
  sectionTextBackground: vars.colors.white,

  sectionImageTextBackground: vars.colors.white,
  sectionImageBackground: vars.colors.white,
};
