const vars = require('components/common/Layout/variables');
const utils = require('utils/styles');

module.exports = {
  name: 'default',
  inherit: null,

  colorPrimary: vars.colors.white,
  colorPrimaryHover: vars.colors.white,
  colorSecondary: vars.colors.black,
  colorSecondaryHover: vars.colors.black,
  colorTertiary: vars.colors.gold,
  colorTertiaryHover: vars.colors.gold,
  colorQuaternary: vars.colors.goldDark,
  colorQuaternaryHover: vars.colors.goldDark,

  backgroundPrimary: vars.colors.greyDarker,
  backgroundPrimaryHover: vars.colors.greyDarker,
  backgroundSecondary: vars.colors.white,
  backgroundSecondaryHover: vars.colors.white,
  backgroundTertiary: vars.colors.gold,
  backgroundTertiaryHover: vars.colors.gold,

  borderColorPrimary: vars.colors.white,
  borderColorPrimaryHover: vars.colors.white,
  borderColorSecondary: vars.colors.black,
  borderColorSecondaryHover: vars.colors.black,
  borderColorTertiary: vars.colors.gold,
  borderColorTertiaryHover: vars.colors.gold,
  borderRadiusPrimary: 10,

  fontPrimary: vars.fonts.primary,
  fontSecondary: vars.fonts.secondary,

  transitionLink: vars.transitions.transitionFaster,

  ...vars.sizes,

  ...vars.fontsizes,

  colors: {
    ...vars.colors,
  },

  // component variables
  buttonDefaultColor: vars.colors.gold,
  buttonDefaultColorHover: vars.colors.goldLight,
  buttonDefaultBackground: 'transparent',
  buttonDefaultBackgroundHover: 'transparent',
  buttonDefaultBorderColor: vars.colors.gold,
  buttonDefaultBorderColorHover: vars.colors.goldLight,

  buttonAlternativeColor: vars.colors.black,
  buttonAlternativeColorHover: vars.colors.white,
  buttonAlternativeBackground: 'transparent',
  buttonAlternativeBackgroundHover: vars.colors.black,
  buttonAlternativeBorderColor: vars.colors.black,
  buttonAlternativeBorderColorHover: vars.colors.black,

  buttonGoldColor: vars.colors.gold,
  buttonGoldColorHover: vars.colors.white,
  buttonGoldBackground: 'transparent',
  buttonGoldBackgroundHover: vars.colors.gold,
  buttonGoldBorderColor: vars.colors.gold,
  buttonGoldBorderColorHover: vars.colors.gold,

  bodyBackground: vars.colors.black,

  headlineGoldBackground: vars.gradients.gold,

  footerMenuLinkColor: vars.colors.goldBright,

  overlayBackground: utils.gradientLinear(
    '135deg',
    vars.gradients.whiteToBlack,
    vars.alphaMaps.fiftyFifty,
  ),

  overlayGradientBackgroundTop: utils.gradientLinear(
    'to top',
    vars.gradients.blackTriple,
    vars.alphaMaps.zeroToSeventyFiveToHundred,
  ),

  overlayGradientBackgroundBottom: utils.gradientLinear(
    'to bottom',
    vars.gradients.blackTriple,
    vars.alphaMaps.zeroToSeventyFiveToHundred,
  ),

  overlayGradientBackgroundLeft: utils.gradientLinear(
    'to left',
    vars.gradients.blackTriple,
    vars.alphaMaps.zeroToSeventyFiveToHundred,
  ),

  overlayGradientBackgroundRight: utils.gradientLinear(
    'to right',
    vars.gradients.blackTriple,
    vars.alphaMaps.zeroToSeventyFiveToHundred,
  ),

  headerGradient: utils.gradientLinear(
    'to top',
    vars.gradients.blackToBlack,
    vars.alphaMaps.zeroToHundred,
  ),

  menuGradient: utils.gradientLinear(
    'to right bottom',
    vars.gradients.greyDarkToBlack,
  ),

  linkColor: vars.colors.white,
  linkColorHover: vars.colors.white,
  linkBorderColor: 'transparent',
  linkBorderColorHover: vars.colors.gold,
  linkBackgroundColor: 'transparent',
  linkBackgroundColorHover: 'transparent',

  scrollBarThumb: '#333333',
  scrollBarTrack: '#cccccc',
  scrollBarWidth: '2px',

  gridSpaceGutter: vars.sizes.spaceDouble,
  sizeHeaderHeight: 120,
  sizeFooterHeight: 70,
};
