import styled from 'utils/emotion';

import media from 'utils/mediaqueries';
import { toPx } from 'utils/styles';

import { wrapSizes } from 'components/common/Layout/variables';

export const GridWrapStyled = styled('div')(
  {
    position: 'relative',
  },
  (props) => ({
    display: props.display,
    margin: props.margin,
    padding: props.padding,
    zIndex: props.zIndex,
    height: props.height,
    flex: props.flex,
    // Automap wrapSizes to create wraps, otherwise you would have to add them by hand if you change something ~DH
    ...Object.assign(
      {},
      ...Object.keys(wrapSizes).map((key, i) =>
        // Avoid first query by default since its mobile first ~DH
        i < 1
          ? {
              width: `calc(${toPx(wrapSizes[key])} - ${toPx(props.gutter)})`,
            }
          : {
              [media(`${key}`)]: {
                width: `calc(${toPx(wrapSizes[key])} - ${toPx(props.gutter)})`,
              },
            },
      ),
    ),
  }),
);
