import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

export default function Image({
  fluid,
  fixed,
  src,
  fadeIn,
  title,
  altText,
  className,
  style,
  imgStyle,
  placeholderStyle,
  placeholderClassName,
  backgroundColor,
  onLoad,
  onStartLoad,
  onError,
  element,
  critical,
  objectFit,
  objectPosition,
}) {
  // Use this technic to always use the same source prop for easier usage ~DH
  let srcFluid = null;
  let srcFixed = null;

  if (src?.childImageSharp?.hasOwnProperty('fluid')) {
    srcFluid = src?.childImageSharp?.fluid;
  } else if (src?.childImageSharp?.hasOwnProperty('fixed')) {
    srcFixed = src?.childImageSharp?.fixed;
  }

  
  // Fallback if no source is given, otherwise GatsbyImage throws an exception ~DH
  if (!!src && !!fluid && !!fixed) {
    return null;
  }
  
  //console.log( src, fluid, fixed)
  return (
    <Img
      fluid={fluid || srcFluid}
      fixed={fixed || srcFixed}
      fadeIn={fadeIn}
      title={title}
      alt={altText}
      className={className}
      style={style}
      imgStyle={{
        ...imgStyle,
        objectFit,
        objectPosition,
        fontFamily: `"object-fit: ${objectFit}; object-position: ${objectPosition}"`,
      }}
      placeholderStyle={placeholderStyle}
      placeholderClassName={placeholderClassName}
      backgroundColor={backgroundColor}
      onLoad={onLoad}
      onStartLoad={onStartLoad}
      onError={onError}
      Tag={element}
      critical={critical}
    />
  );
}

Image.propTypes = {
  fluid: PropTypes.object,
  fixed: PropTypes.object,
  src: PropTypes.object,
  fadeIn: PropTypes.bool,
  title: PropTypes.string,
  altText: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  style: PropTypes.object,
  imgStyle: PropTypes.object,
  placeholderStyle: PropTypes.object,
  placeholderClassName: PropTypes.string,
  backgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onLoad: PropTypes.func,
  onStartLoad: PropTypes.func,
  onError: PropTypes.func,
  element: PropTypes.string,
  critical: PropTypes.bool,
  objectFit: PropTypes.string,
  objectPosition: PropTypes.string,
};

Image.defaultProps = {
  fluid: null, // Data returned from the fixed query
  fixed: null, // Data returned from the fluid query
  src: null, // Automatic detection of fluid or fixed ~DH
  fadeIn: true, // Defaults to fading in the image on load
  title: '', // Passed to the img element
  altText: '',
  className: '', // Passed to the wrapper element. Object is needed to support Glamor’s css prop
  style: undefined, // Spread into the default styles of the wrapper element
  imgStyle: null, // Spread into the default styles of the actual img element
  placeholderStyle: null, // Spread into the default styles of the placeholder img element
  placeholderClassName: '', // A class that is passed to the placeholder img element
  backgroundColor: 'transparent', // Set a colored background placeholder. If true, uses “lightgray” for the color. You can also pass in any valid color string.
  onLoad: null, // A callback that is called when the full-size image has loaded.
  onStartLoad: null, // A callback that is called when the full-size image starts loading, it gets the parameter { wasCached: boolean } provided.
  onError: null, // A callback that is called when the image fails to load.
  element: 'div', // Which HTML tag to use for wrapping elements. Defaults to div. Originally `Tag` but thats stupid ~DH
  critical: false, // Opt-out of lazy-loading behavior. Defaults to false.
  objectFit: 'cover',
  objectPosition: '50% 50%',
};

export const defaultImage = graphql`
  fragment imageDefault on File {
    childImageSharp {
      fluid(quality: 100, maxWidth: 1920) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`;
