import React from 'react';
import PropTypes from 'prop-types';
// Hotfix for https://github.com/gatsbyjs/gatsby/issues/9489 until a newer react version is out ~DH
import { setConfig } from 'react-hot-loader';
setConfig({ pureSFC: true });

import { GridContext } from 'components/common/Grid';

import { GridItemStyled } from './GridItemStyled';

export default function GridItem(props) {
  const {
    children,
    display,
    height,
    first,
    last,
    top,
    middle,
    bottom,
    background,
    textAlign,
    zIndex,
    className,
    ...rest
  } = props;

  return (
    <GridContext.Consumer>
      {({ gutter }) => (
        <GridItemStyled
          display={display}
          gutter={gutter}
          height={height}
          textAlign={textAlign}
          background={background}
          zIndex={zIndex}
          className={className}
          {...rest}
        >
          {children}
        </GridItemStyled>
      )}
    </GridContext.Consumer>
  );
}

GridItem.propTypes = {
  mobile: PropTypes.number, // Remove this in next project ~DH
  first: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  last: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  zIndex: PropTypes.string,
  display: PropTypes.string,
  top: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  middle: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  bottom: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
};

GridItem.defaultProps = {
  mobile: 1, // Remove this in next project ~DH
  first: null,
  last: null,
  zIndex: null,
  display: null,
  top: null,
  middle: null,
  bottom: null,
};
