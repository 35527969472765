import React from 'react';
import PropTypes from 'prop-types';

import { TextPStyled, TextSpanStyled, TextDivStyled } from './TextStyled';

export default function Text({ element, children, className }) {
  let elementSwitched;

  switch (element) {
    case 'p':
    default:
      elementSwitched = (
        <TextPStyled className={className}>{children}</TextPStyled>
      );
      break;
    case 'span':
      elementSwitched = (
        <TextSpanStyled className={className}>{children}</TextSpanStyled>
      );
      break;
    case 'div':
      elementSwitched = (
        <TextDivStyled className={className}>{children}</TextDivStyled>
      );
      break;
  }

  return elementSwitched;
}

Text.propTypes = {
  element: PropTypes.string,
};

Text.defaultProps = {
  element: 'p',
};
