import styled from 'utils/emotion';

import Headline from 'components/common/Headline';

export const HeadlineGoldStyled = styled(Headline)(
  ({ theme, goldTexture }) => ({
    color: goldTexture ? theme.colorTertiary : 'inherit',
    display: 'inline-block',
    margin: 0,

    strong: {
      fontWeight: 700,
      color: 'inherit',
    },

    '@supports(-webkit-background-clip: text)': goldTexture
      ? {
          background: `url(${goldTexture})`,
          backgroundSize: 'cover',
          backgroundPosition: '50% 50%',
          WebkitTextFillColor: 'transparent',
          WebkitBackgroundClip: 'text',
        }
      : undefined,
  }),
);
