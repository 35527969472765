export const fonts = {
  primary: 'futura-pt, sans-serif',
  secondary: 'futura-pt-condensed, sans-serif',
};

export const colors = {
  black: '#000000',
  brown: '#393939',
  brownLight: '#c8c2b7',
  white: '#ffffff',
  goldLight: '#F2D695',
  gold: '#d5ba7b',
  goldDark: '#8a764e',
  goldBright: '#D6C69F',
  greyDark: '#1D1D1D',

  error: '#EF4C4C',
  success: '#24AA62',
};

export const gradients = {
  whiteToWhite: [colors.white, colors.white],
  whiteTriple: [colors.white, colors.white, colors.white],
  whiteToBlack: [colors.white, colors.black],
  brownToBlack: [colors.brown, colors.black],
  blackToBlack: [colors.black, colors.black],
  greyDarkToBlack: [colors.greyDark, colors.black],
  blackTriple: [colors.black, colors.black, colors.black],
  gold:
    'radial-gradient( ellipse farthest-corner at right bottom, #584416 0%, #e6c26e 8%, #b7a377 30%, #a58a4d 40%, transparent 80% ), radial-gradient( ellipse farthest-corner at left top, #ffedba 0%, #e8ce85 8%, #d8c492 25%, #d1b464 62.5%, #d1b464 100% );',
};

export const transitions = {
  transitionLightspeed: '30ms',
  transitionFaster: '150ms',
  transitionFast: '300ms',
  transitionMedium: '900ms',
  transitionLong: '1200ms',
};

// The problem with numbers here is that 0 is handled as null which is
// problematic for gradient util function if someone knows better let me know ~DH
export const alphaMaps = {
  zeroToHundred: ['0', '1'],
  zeroHundredHundred: ['0', '1', '1'],
  hundredToZero: ['1', '0'],
  fiftyFifty: ['0.5', '0.5'],
  zeroToSeventyFiveToHundred: ['0', '0.75', '1'],
};

export const positionMaps = {
  zeroEightyHundred: ['0%', '80%', '100%'],
};

export const sizes = {
  spaceEight: 3,
  spaceSixt: 4,
  spaceQuarter: 6,
  spaceThird: 8,
  spaceHalf: 12,
  spaceThirds: 18,
  space: 24,
  spaceEighted: 32,
  spaceQuartered: 36,
  spaceDouble: 48,
  spaceDoubleEight: 56,
  spaceDoubleThirds: 64,
  spaceTriple: 72,
  spaceQuad: 96,
  spaceFive: 120,
  spaceTen: 240,
  spaceFifteen: 360,
  spaceTwenty: 480,
  spaceThirty: 720,
  spaceFourty: 960,
  spaceFifty: 1200,
  spaceSixty: 1440,
  spaceHundredTwenty: 2880,
};

// Use this as reference https://easings.net/de ~DH
export const easings = {
  easeOutQuad: [0.25, 0.46, 0.45, 0.94],
};

// Font Settings
export const fontsizes = {
  baseTextBodyFontSize: '18px',
  baseTextBodyFontSizeDesktop: '20px',
  baseTextBodyLineHeight: '1.6',
};

export const breakpoints = {
  mobile: 0,
  tablet: 481,
  tabletFluid: 737,
  laptop: 977,
  desktop: 1440,
  desktopFluid: 1600,
};

export const wrapSizes = {
  mobile: '100%',
  tablet: '100%',
  tabletFluid: 720,
  laptop: 960,
  desktop: 1240,
  desktopFluid: 1440,
};
