// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-categories-beer-varieties-cats-js": () => import("./../../../src/templates/categories/beerVarietiesCats.js" /* webpackChunkName: "component---src-templates-categories-beer-varieties-cats-js" */),
  "component---src-templates-content-pages-js": () => import("./../../../src/templates/contentPages.js" /* webpackChunkName: "component---src-templates-content-pages-js" */),
  "component---src-templates-error-404-js": () => import("./../../../src/templates/error404.js" /* webpackChunkName: "component---src-templates-error-404-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-info-pages-js": () => import("./../../../src/templates/infoPages.js" /* webpackChunkName: "component---src-templates-info-pages-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */)
}

