import React from 'react';
import PropTypes from 'prop-types';
import styled from 'utils/emotion';
import uuid from 'uuid/dist/v4';

import themeDefault from 'components/common/Layout/themes/default';

import * as Icons from './Icons';

const ICON_MAP = {
  socialFacebook: {
    icon: Icons.SocialFacebook,
    props: {
      viewBox: '0 0 16.719 30',
    },
  },
  socialFacebookSquare: {
    icon: Icons.SocialFacebookSquare,
    props: {
      viewBox: '0 0 17 17',
    },
  },
  socialInstagram: {
    icon: Icons.SocialInstagram,
    props: {
      viewBox: '0 0 17 17',
    },
  },
  socialTwitter: {
    icon: Icons.SocialTwitter,
    props: {
      viewBox: '0 0 36.918 30',
    },
  },
  socialYoutube: {
    icon: Icons.SocialYoutube,
  },
  warBadge: {
    icon: Icons.WarBadge,
    props: {
      viewBox: '0 0 91.105 52.919',
      width: '80',
      height: '80',
    },
  },
  logoWarsteiner: {
    icon: Icons.LogoWarsteiner,
    props: {
      viewBox: '0 0 80 80',
      width: '80',
      height: '80',
    },
  },
  warsteinerBottleOutline: {
    icon: Icons.WarsteinerBottleOutline,
    props: {
      viewBox: '0 0 36 117',
      width: '36',
      height: '117',
    },
  },
  hamburger: {
    icon: Icons.Hamburger,
    props: {
      viewBox: '0 0 21 14',
    },
  },
  arrowLeft: {
    icon: Icons.ArrowLeft,
    props: {
      viewBox: '0 0 35 12.372',
      width: '35',
    },
  },
  arrowRight: {
    icon: Icons.ArrowRight,
    props: {
      viewBox: '0 0 35 12.372',
      width: '35',
    },
  },
  arrowDown: {
    icon: Icons.ArrowDown,
    props: {
      viewBox: '0 0 12.371 35',
      width: '12.371',
    },
  },
  arrowMoreDown: {
    icon: Icons.ArrowMoreDown,
    props: {
      viewBox: '0 0 12 7.41',
      width: '12',
    },
  },
  backArrow: {
    icon: Icons.BackArrow,
    props: {
      viewBox: '0 0 39 39',
      width: '39px',
      height: '39px',
    },
  },
  chevronLeft: {
    icon: Icons.ChevronLeft,
    props: {
      viewBox: '0 0 24 24',
    },
  },
  chevronRight: {
    icon: Icons.ChevronRight,
    props: {
      viewBox: '0 0 24 24',
    },
  },
};

const StyledSvg = styled('svg')({
  maxWidth: '100%',
});

export default function Icon({
  type,
  width,
  height,
  viewBox,
  preserveAspectRatio,
  fillPrimary,
  fillSecondary,
  fillTertiary,
  currentColor,
  className,
}) {
  // Avoid obsolete nesting within iconMap if there are no settings
  const IconContent = ICON_MAP?.[type]?.icon || ICON_MAP?.[type];

  // Avoid reference bugs with a unique id.
  const iconId = uuid();

  return IconContent ? (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      data-icon={type}
      width={width || ICON_MAP?.[type]?.props?.width || '24px'}
      height={height || ICON_MAP?.[type]?.props?.height || '24px'}
      viewBox={viewBox || ICON_MAP?.[type]?.props?.viewBox || '0 0 24 24'}
      preserveAspectRatio={preserveAspectRatio}
      className={className}
    >
      <IconContent
        id={iconId}
        fillPrimary={currentColor ? 'currentColor' : fillPrimary}
        fillSecondary={currentColor ? 'currentColor' : fillSecondary}
        fillTertiary={fillTertiary}
      />
    </StyledSvg>
  ) : null;
}

/* eslint-disable react/require-default-props */
// We set the default values/props for height, width and viewbox within component as fallback
Icon.propTypes = {
  type: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  viewBox: PropTypes.string,
  preserveAspectRatio: PropTypes.string,
  fillPrimary: PropTypes.string,
  fillSecondary: PropTypes.string,
  fillTertiary: PropTypes.string,
  currentColor: PropTypes.bool,
};

Icon.defaultProps = {
  type: 'arrowLeft',
  preserveAspectRatio: 'xMidYMid meet',
  currentColor: true,
  fillPrimary: themeDefault.colorPrimary,
  fillSecondary: themeDefault.colorSecondary,
  fillTertiary: themeDefault.colorTertiary,
};
