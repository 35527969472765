import styled from 'utils/emotion';
import { toPx, isBrowserIE11 } from 'utils/styles';
import media from 'utils/mediaqueries';

import Link from 'components/common/Link';
import Icon from 'components/common/Icon';

export const StyledButtonIconLeft = styled(Icon)(
  {
    flex: 'none',
  },
  (props) => ({
    marginRight: `${toPx(props.theme.spaceQuartered)}`,
  }),
);

export const StyledButtonIconRight = styled(Icon)(
  {
    flex: 'none',
  },
  (props) => ({
    marginLeft: `${toPx(props.theme.spaceQuartered)}`,
  }),
);

export const StyledButtonContent = styled('span')({
  display: 'flex',
  flexBasis: '100%',
  alignItems: 'center',
});

export const StyledButtonText = styled('span')(
  (props) =>
    props.forceButtonTextShrink && {
      flex: '0',
    },
  // here comes a very pretty condition that checks if we are having IE 11
  // IE 11 doesn't know jack shit about flex-base, so we can't do flex: 0
  // unless you know how?
  () =>
    isBrowserIE11() && {
      flex: '0 1 auto',
    },
);

export const StyledButton = styled(Link)(
  {
    position: 'relative',
    display: 'inline-block',
    width: 'auto',
    fontWeight: 'normal',
    fontSize: 20,
    transition: 'color .2s, border .2s',
    textTransform: 'uppercase',
    borderRadius: 0,
    cursor: 'pointer',
    [media('tablet', 'tabletFluid')]: {
      minWidth: 0,
    },
    minWidth: '180px', // Kundenwunsch :] ~DH
    textAlign: 'center',

    '&::before, &::after': {
      position: 'absolute',
      display: 'inline-block',
      content: '""',
      width: '100%',
      height: '60%',
      transition: 'transform ease-in-out .25s 0s',
    },

    '&::before': {
      top: 0,
      left: 0,
      borderTop: '1px solid currentColor',
      transform: 'translate(0,0)',
    },

    '&::after': {
      bottom: 0,
      right: 0,
      borderBottom: '1px solid currentColor',
      transform: 'translate(0,0)',
    },

    '&:focus, &:active': {
      WebkitTapHighlightColor: 'transparent',
    },

    '&:hover': {
      '&::before': {
        transform: 'translateX(-5px)',
      },

      '&::after': {
        transform: 'translateX(5px)',
      },
    },
  },
  (props) => ({
    fontFamily: props.theme.fontSecondary,
    textAlign: props.textAlign,
    margin: props.margin,
    padding: '14px 40px',

    svg: {
      transition: 'transform .2s ease-in-out',
    },

    '&:hover, &:focus, &:active': {
      color: 'initial',
    },
  }),
  (props) =>
    props.theme.name === 'default' && {
      color: props.theme.buttonDefaultColor,
      backgroundColor: props.theme.buttonDefaultBackground,
      borderColor: props.theme.buttonDefaultBorderColor,

      '&:hover, &:focus, &:active': {
        color: props.theme.buttonDefaultColorHover,
        backgroundColor: props.theme.buttonDefaultBackgroundHover,
        borderColor: props.theme.buttonDefaultBorderColorHover,

        '&::before, &::after': {
          color: props.theme.buttonDefaultColor,
        },
      },
    },
  (props) =>
    props.icon && {
      display: 'inline-flex',
      alignItems: 'center',
    },
  (props) =>
    props.disabled && {
      opacity: 0.3,
      pointerEvents: 'none',
    },
);
