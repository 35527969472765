import React from 'react';
import PropTypes from 'prop-types';

import NullWrap from 'components/common/NullWrap';

import { GridWrapStyled } from './GridWrapStyled';

export default function GridWrap({
  children,
  margin,
  padding,
  height,
  isNested,
  display,
  flex,
  gutter,
  className,
}) {
  const WrapComponent = isNested ? NullWrap : GridWrapStyled;
  return (
    <WrapComponent
      gutter={gutter}
      margin={margin}
      padding={padding}
      height={height}
      display={display}
      flex={flex}
      className={className}
      isGlobalGridWrapper={!isNested}
    >
      {children}
    </WrapComponent>
  );
}

GridWrap.propTypes = {
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gutter: PropTypes.number,
};

GridWrap.defaultProps = {
  margin: '0 auto',
  gutter: 24,
};
