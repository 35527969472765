import styled from '@emotion/styled';

import { css as cssEmotion, cx as cxEmotion } from 'emotion';

import { withTheme as withThemeEmotion } from 'emotion-theming';

// we use the utils/emotion file as single source of truth transition file to make future updates easier
export const css = cssEmotion;
export const cx = cxEmotion;
export const withTheme = withThemeEmotion;

export default styled;
