import styled from 'utils/emotion';

import media from 'utils/mediaqueries';
import { toPx } from 'utils/styles';

import Icon from 'components/common/Icon';
import Text from 'components/common/Text';
import Button from 'components/common/Button';
import HeadlineGold from 'components/common/HeadlineGold';

import { sizes, colors } from 'components/common/Layout/variables';

export const AgegateStyled = styled('section')({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: '100%',
  height: '100vh',
  overflowY: 'auto',
  overflowX: 'hidden',
  boxSizing: 'border-box',
  color: colors.white,
  background: '#000',
  zIndex: 1000,
});

export const InnerStyled = styled('div')({
  padding: `${toPx(sizes.spaceThirds)} ${toPx(sizes.space)}`,
  maxWidth: 720,
  width: '100%',
  margin: '0 auto',

  // Very special query for renes screen size.
  // Because we want him to have the greatest experience with the age gate.
  [media(0, 750, 'landscape', { axis: 'height' })]: {
    maxWidth: 1240,
  },
});

/**
 * Content
 */
export const IconStyled = styled(Icon)({
  display: 'block',
  width: '90px',
  height: '90px',
  margin: '0 auto',

  [media('tablet')]: {
    width: '120px',
    height: '120px',
  },

  [media('tabletFluid')]: {
    marginTop: sizes.space,
  },
});

export const ContentStyled = styled('div')({
  textAlign: 'center',
  margin: `${toPx(sizes.spaceDouble)} auto`,

  [media('tabletFluid')]: {
    margin: `${toPx(sizes.spaceTriple)} auto`,
  },
});

export const TextStyled = styled(Text)({
  marginBottom: sizes.space,
});

export const HeadlineStyled = styled(HeadlineGold)({
  fontWeight: 800,
  fontSize: 27,
  lineHeight: 1.2,
  marginBottom: sizes.space,
});

/**
 * Buttons
 */
export const ButtonWrapStyled = styled('div')({
  textAlign: 'center',
  marginBottom: 120,
});

export const ButtonAcceptStyled = styled(Button)({
  display: 'block',
  maxWidth: 270,
  margin: `0 auto ${toPx(sizes.space)}`,
});

export const ButtonDeclineStyled = styled(Button)({
  display: 'block',
  maxWidth: 270,
  margin: `0 auto ${toPx(sizes.space)}`,
});

export const FooterStyled = styled('footer')({
  position: 'absolute',
  bottom: 10,
  width: '100%',
  textAlign: 'center',

  a: {
    color: colors.gold,
    fontSize: '.8em',
    margin: '0 .5rem',
  },
});
