import React from 'react';
import PropTypes from 'prop-types';

import { VideoWrapperStyled, VideoWrapperInnerStyled } from './VideoStyled';

export default function Video({ children }) {
  return (
    <VideoWrapperStyled>
      <VideoWrapperInnerStyled>{children}</VideoWrapperInnerStyled>
    </VideoWrapperStyled>
  );
}

Video.propTypes = {};

Video.defaultProps = {};
