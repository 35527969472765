import styled from 'utils/emotion';

import Icon from 'components/common/Icon';

// Need a wrapper to fix a weird issue with the header (loading in index.js)
export const LayoutStyled = styled('div')({
  position: 'relative',
  zIndex: 500000,
});

export const LayoutOverlayStyled = styled('div')({
  position: 'fixed',
  zIndex: 500000,
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  background: '#000',
  pointerEvents: 'none',
  transition: 'opacity 0.2s ease-in-out',
  overflow: 'hidden',
});

export const LayoutOverlayIconStyled = styled(Icon)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '40vw',
  maxWidth: '240px',
  height: '40vw',
  maxHeight: '240px',
  transform: 'translate(-50%,-50%)',
});
