import React from 'react';
import striptags from 'striptags';
import HtmlToReact from 'html-to-react';
import ent from 'ent';

import Text from 'components/common/Text';
import Headline from 'components/common/HeadlineGold';
import Link from 'components/common/Link';
import Blockquote from 'components/common/Blockquote';
import Strong from 'components/common/Strong';
import Table from 'components/common/Table';
import Video from 'components/common/Video';

const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);

const tags = {
  basic: ['a', 'strong', 'b', 'em', 'i', 'u', 'br'],
  advanced: [
    'ul',
    'ol',
    'li',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'table',
    'thead',
    'tbody',
    'tfoot',
    'tr',
    'td',
    'th',
    'iframe',
    'blockquote',
  ],
  auxiliary: ['strong', 'b', 'em', 'i', 'caption'],
};

const allBasicTags = [...tags.basic];
const allTags = [...tags.basic, ...tags.advanced];
const allTagsWithAuxiliary = [...allTags, ...tags.auxiliary];

export function limitCharacters(string, limit = 160, separator = ' ') {
  if (!string || string.length <= limit) return string;
  return string.substr(0, string.lastIndexOf(separator, limit));
}

function processContentWrapper(_, children) {
  return (
    <span key={0} data-cms>
      {children}
    </span>
  );
}

function processParagraphNode(node, children, index) {
  return (
    <Text key={index} element={node.name}>
      {children}
    </Text>
  );
}

function processBreakNode(node, children, index) {
  return <br key={index} element={node.name} />;
}

function processBlockquoteNode(node, children, index) {
  return (
    <Blockquote key={index} element={node.name}>
      {children}
    </Blockquote>
  );
}

function processTableNode(node, children, index) {
  return (
    <Table key={index} element={node.name}>
      {children}
    </Table>
  );
}

function processVideoNode(node, children, index) {
  return (
    <Video key={index} element={node.name}>
      {processNodeDefinitions.processDefaultNode(node, children, index)}
    </Video>
  );
}

function processHeadlineNode(node, children, index) {
  const id = node.attribs.id || undefined;
  const name = node.attribs.name || undefined;
  const element = node.name || undefined;

  return (
    <Headline key={index} id={id} name={name} element={element}>
      {children}
    </Headline>
  );
}

function processLinkNode(node, children, index) {
  const href = node.attribs.href || null;
  const target = node.attribs.target || null;
  const rel = node.attribs.rel || null;

  return (
    <Link key={index} to={href} target={target} rel={rel}>
      {children}
    </Link>
  );
}

function processBoldNode(node, children, index) {
  return <Strong key={index}>{children}</Strong>;
}

function processItalicNode(node, children, index) {
  return <em key={index}>{children}</em>;
}

/* eslint no-bitwise: 0 */
// this is legit usage ~DH
const isValidNode = node =>
  ~tags.basic.indexOf(node.name) ||
  ~tags.advanced.indexOf(node.name) ||
  ~tags.auxiliary.indexOf(node.name) ||
  node.type === 'text';

function parseHtml(html) {
  const processingInstructions = [
    {
      shouldProcessNode: node => node.name === 'cwrapper',
      processNode: processContentWrapper,
    },
    {
      shouldProcessNode: node => node.name === 'p',
      processNode: processParagraphNode,
    },
    {
      shouldProcessNode: node => node.name === 'br',
      processNode: processBreakNode,
    },
    {
      shouldProcessNode: node => node.name === 'blockquote',
      processNode: processBlockquoteNode,
    },
    {
      shouldProcessNode: node => node.name === 'table',
      processNode: processTableNode,
    },
    {
      shouldProcessNode: node => node.name === 'a',
      processNode: processLinkNode,
    },
    {
      shouldProcessNode: node => node.name === 'b',
      processNode: processBoldNode,
    },
    {
      shouldProcessNode: node => node.name === 'strong',
      processNode: processBoldNode,
    },
    {
      shouldProcessNode: node => node.name === 'i',
      processNode: processItalicNode,
    },
    {
      shouldProcessNode: node => node.name && node.name.match(/h[1-6]/),
      processNode: processHeadlineNode,
    },
    {
      shouldProcessNode: node =>
        node.name === 'iframe' &&
        node.attribs.src.match(/vimeo\.com|youtube\.com|youtu\.be/),
      processNode: processVideoNode,
    },
    {
      shouldProcessNode: isValidNode,
      processNode: processNodeDefinitions.processDefaultNode,
    },
  ];
  const htmlToReactParser = new HtmlToReact.Parser(React);
  return htmlToReactParser.parseWithInstructions(
    `<cwrapper>${html}</cwrapper>`,
    () => true,
    processingInstructions,
  );
}

export function removeScripts(text) {
  return text.replace(/<script([\S\s]*?)<\/script>/gi, '');
}

export function removeDangerousTags(html) {
  return removeScripts(html);
}

/**
 * Function:
 * Parse as text
 *
 * @param {*} html
 */
export function parseAsText(html) {
  if (!html) return null;

  let htmlMutate = html;

  htmlMutate = String(html);
  htmlMutate = removeDangerousTags(html);

  const parsed = ent.decode(striptags(htmlMutate));
  return parsed;
}

/**
 * Function:
 * Parse as content
 *
 * @param {*} html
 * @param {*} enableAuxiliaryTags
 */
export function parseAsContent(
  html,
  { enableAuxiliaryTags = false, onlyBasicTags = false } = {},
) {
  if (!html) return null;

  let htmlMutate = html;

  htmlMutate = String(htmlMutate).replace(/(\r\n|\r|\n)/g, '');
  htmlMutate = removeScripts(htmlMutate);

  if (onlyBasicTags) {
    htmlMutate = striptags(htmlMutate, allBasicTags);
  } else {
    htmlMutate = striptags(
      htmlMutate,
      enableAuxiliaryTags ? allTagsWithAuxiliary : allTags,
    );
  }

  return parseHtml(htmlMutate);
}

const NEWLINE_REGEX = /(\r\n|\r|\n)/g;
export function nl2br(str) {
  if (typeof str === 'number') {
    return str;
  }
  if (typeof str !== 'string') {
    return '';
  }

  return str.split(NEWLINE_REGEX).map((line, index) => {
    if (line.match(NEWLINE_REGEX)) {
      // eslint-disable-next-line react/no-array-index-key
      return <br key={index} />;
    }
    return line;
  });
}
