import React from 'react';
import PropTypes from 'prop-types';

import { parseAsText } from 'utils/parser';

import {
  TileStyled,
  BgStyled,
  TitleStyled,
  TitleInnerStyled,
  LinkStyled,
} from './TileStyled';

/**
 * Helper function:
 *
 * Takes a string and marks the last word with <strong> tags.
 *
 * @param {string} title
 */
function prepareTitle(title) {
  const parsedTitle = parseAsText(title);
  const parts = parsedTitle.split(' ');
  const last = parts.pop();

  return (
    <TitleInnerStyled>
      {parts.join(' ')}
      <br />
      <strong>{last}</strong>
    </TitleInnerStyled>
  );
}

export default function Tile({ image, title, to, onClick, target, active }) {
  const preparedTitle = prepareTitle(title);

  return (
    <TileStyled active={active}>
      {image && (
        <BgStyled
          fluid={image}
          style={{ position: 'absolute' }}
          args={{ maxWidth: 800 }}
          altText={image?.altText}
          critical
        />
      )}
      {preparedTitle && (
        <TitleStyled active={active}>{preparedTitle}</TitleStyled>
      )}
      {to && <LinkStyled to={to} onClick={onClick} target={target} />}
    </TileStyled>
  );
}

Tile.propTypes = {
  image: PropTypes.object,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  target: PropTypes.string,
};

Tile.defaultProps = {
  image: null,
  target: undefined,
  onClick: null,
};
